/* Styling for the Front page */
#front-page {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/background-images/bg-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
hr {
  height: 1px;
  background-color: #ccc;
  z-index: 10;
  border: none;
}
.front-page-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.front-page-logo {
  position: absolute;
  top: -15%;
  opacity: 0.3;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.front-page-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.front-page-heading {
  font-size: 6rem;
  font-weight: 700;
  color: white;
  z-index: 1;
  text-align: left;
}
.front-page-contact-button {
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  width: max-content;
  height: max-content;
  background-color: #007167;
  right: 0;
  top: 70vh;
  padding: 0px 25px;
}
.front-page-contact-button p {
  color: white;
  font-size: 20px;
}
.front-page-contact-button a {
  text-decoration: none;
  text-decoration-style: none;
}
.front-page-counter {
  width: 80%;
  height: 150px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.front-page-counter-days,
.front-page-counter-hours,
.front-page-counter-minutes,
.front-page-counter-seconds {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 25%;
}
.front-page-counter-digits {
  color: rgb(96, 180, 213);
  font-size: 70px;
  font-weight: 700;
}
.front-page-counter-description {
  font-size: 30px;
  color: white;
}
.front-page-description-header {
  font-size: 20px;
  font-weight: 600;
  color: rgb(96, 180, 213);
  padding: 0% 5% 0% 5%;
}
.front-page-description-text {
  font-size: 78px;
  width: 80%;
  color: white;
  text-align: center;
}
.front-page-button {
  color: black;
  background-color: #ccc;
  transition: 0.25s;
  border: none;
  outline: none;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  width: 200px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
}
.front-page-button:hover,
.front-page-button:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(rgb(96, 180, 213));
  transform: translateY(-0.25em);
  background-color: rgb(96, 180, 213);
}
@media screen and (max-width: 1500px) {
  .front-page-heading {
    font-size: 3rem;
  }
  .front-page-description-header {
    font-size: 20px;
    text-align: center;
  }
  .front-page-description-text {
    font-size: 18px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .front-page-wrapper {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .front-page-heading {
    font-size: 1.5rem;
  }
  .front-page-description-header {
    font-size: 16px;
  }
  .front-page-description-text {
    font-size: 16px;
  }
  .front-page-counter {
    width: 90%;
  }
  .front-page-counter-digits {
    font-size: 40px;
  }
  .front-page-counter-description {
    font-size: 15px;
  }
}
