/* Styling for the Footer */
#footer-section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.footer-map {
  width: 100%;
  height: 300px;
}
.footer-logo {
  position: absolute;
  opacity: 0.4;
}
.footer-bottom {
  height: max-content;
  padding: 1% 0%;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.footer-left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer-text-heading {
  font-size: 18px;
  color: #00afaa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.footer-text {
  font-size: 14px;
  color: white;
}
.footer-copyright-text {
  font-size: 14px;
  color: white;
  padding-bottom: 10px;
}
.footer-icon {
  background-color: white;
  height: 36px;
  width: 36px;
  cursor: pointer;
  -webkit-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
}
.footer-icon:hover {
  background-color: #00afaa;
}
.footer-icons {
  display: flex;
  width: 150px;
  justify-content: space-between;
}
.footer-right {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 600px) {
  .footer-contact {
    flex-direction: column;
  }
  .footer-left {
    align-items: center;
    width: 100%;
    padding-top: 5%;
  }
  .footer-icon {
    font-size: 24px;
  }
  .footer-right {
    margin-top: 10%;
    width: 100%;
  }
  .footer-map {
    height: 300px;
  }
  .footer-bottom {
    height: 100%;
  }
  .footer-text-heading {
    width: 80%;
  }
  .footer-text {
    width: 80%;
  }
}
