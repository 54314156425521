/* Styling for the Navbar */
nav {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  padding: 1% 5%;
  height: 80px;
}
nav .logo {
  float: left;
  padding-top: 20px;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url("../../assets/background-images/gorusica-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.5s;
}
nav .logo:hover {
  background-image: url("../../assets/background-images/gorusica-white.png");
}
nav .links {
  float: right;
  padding: 0;
  margin: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav .links li {
  list-style: none;
  cursor: pointer;
}
nav .links a {
  display: block;
  padding: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
}
nav .links a:hover {
  color: #00afaa;
}
#nav-toggle {
  position: fixed;
  top: -100px;
}
nav .icon-burger {
  display: none;
  position: fixed;
  right: 5%;
  top: 5%;
  transform: translateY(-50%);
}
nav .icon-burger .line {
  width: 30px;
  height: 5px;
  background-color: #00afaa;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  nav {
    padding: 0% 5%;
  }
  nav .links {
    float: none;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 100%;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    justify-content: space-evenly;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 55, 100, 0.8);
  }
  nav .links a {
    font-size: 20px;
  }
  nav :checked ~ .links {
    bottom: 60%;
  }
  nav :checked ~ .links {
    background-color: rgba(0, 55, 100, 0.8);
  }
  nav :checked ~ .logo {
    display: none;
  }
  nav .icon-burger {
    display: block;
    z-index: 10;
  }
  nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }
  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }
  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }
}
