/* Styling for the Media page */
#AboutPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-page-wrapper {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-page-left {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-heading {
  font-size: 70px;
  font-weight: 700;
  color: black;
  text-align: left;
}
.about-page-description-text {
  font-size: 24px;
  color: black;
  width: 80%;
  text-align: justify;
}
.about-page-gallery {
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.about-page-gallery a {
  text-decoration: none;
}
.gallery-box {
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  margin: 25px 0 30px 0;
  box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
  transition: transform 0.2s;
  color: white;
}
.gallery-box:hover {
  transform: scale(1.05);
}
.gallery-box-content-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 25%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.gallery-box-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.gallery-box-content-heading p {
  font-size: 18px;
}
.gallery-box-content-heading span {
  font-size: 14px;
}
.gallery-box-content p {
  text-align: center;
  font-weight: bold;
  color: #00afaa;
}
.gallery-box-first,
.gallery-box-second,
.gallery-box-third {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.gallery-box-first {
  background-image: url("../../assets/background-images/pupil-2.jpg");
  background-position: top;
}
.gallery-box-second {
  background-image: url("../../assets/background-images/student-2.jpg");
  background-position: center;
}
.gallery-box-third {
  background-image: url("../../assets/background-images/supplies.jpg");
  background-position: center;
}

@media screen and (max-width: 2000px) {
  .about-page-heading {
    font-size: 50px;
    text-align: center;
  }
  .about-page-description-text {
    font-size: 18px;
    width: 70%;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .about-page-heading {
    font-size: 30px;
    margin-top: 0;
  }
  .about-page-description-text {
    width: 80%;
  }
  .about-page-wrapper {
    padding: 5% 0 5% 0;
  }
}

@media screen and (max-width: 600px) {
  .about-page-heading {
    font-size: 24px;
    padding-top: 20px;
  }
  .about-page-description-text {
    font-size: 16px;
    width: 70%;
  }
}
